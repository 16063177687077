import Api from '@/api'
//import store from '..'

export default {
  state: {
    list: [],
    groups: [],
    isUserOper: false
  },
  actions: {
    async LOAD_USERS_LIST({commit}) {
      try {
        const res = await Api.load_users_list()
        const {status} = res
        if (status === 200) {
          commit('SET_USERS_LIST', res.data)
        } else {
          throw 'Error LOAD_USERS_LIST'
        }
      } catch (err) {
        throw `Error LOAD_USERS_LIST: ${err}`
      }
    },
    async LOAD_USERS_GROUPS({commit, getters}) {
      try {
        const res = await Api.load_users_groups()
        const locale = getters.locale_active
        const {status} = res
        if (status === 200) {
          const group_list = res.data.map((group) => {
            return {id: group.id, name: group[`name_${locale}`]}
          })
          commit('SET_USERS_GROUPS', group_list)
        } else {
          throw 'Error LOAD_USERS_GROUPS'
        }
      } catch (err) {
        throw `Error LOAD_USERS_GROUPS: ${err}`
      }
    },
    async LOAD_USER_BY_ID({commit}, id) {
      try {
        commit('SET_USER_OPER', true)
        const res = await Api.load_user_by_id(id)
        const {status} = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_USER_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      } finally {
        commit('SET_USER_OPER', false)
      }
    },
    async ADD_USER({commit}, user) {
      try {
        commit('SET_USER_OPER', true)
        const res = await Api.add_user(user)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw `${err.response ? err.response.data.message : err}`
      } finally {
        commit('SET_USER_OPER', false)
      }
    },
    async UPD_USER({commit}, payload) {
      try {
        const {id, user} = payload
        commit('SET_USER_OPER', true)
        const res = await Api.upd_user(id, user)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw `${err.response.data.message}`
      } finally {
        commit('SET_USER_OPER', false)
      }
    },
    async DEL_USER({commit}, id) {
      try {
        commit('SET_USER_OPER', true)
        const res = await Api.del_user(id)
        if (res.status === 200) {
          commit('DEL_USER_FROM_LIST', id)
        } else {
          throw res.status
        }
      } catch (err) {
        throw `${err.response.data.message}`
      } finally {
        commit('SET_USER_OPER', false)
      }
    }
  },
  mutations: {
    SET_USERS_LIST(state, list) {
      state.list = [...list]
    },
    DEL_USER_FROM_LIST(state, id) {
      let ind = state.list.findIndex((row) => row.id === id)
      if (ind >= 0) {
        state.list.splice(ind, 1)
      }
    },
    SET_USERS_GROUPS(state, list) {
      state.groups = [...list]
    },
    SET_USER_OPER(state, val) {
      state.isUserOper = val
    }
  },
  getters: {}
}
