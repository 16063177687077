import Vue from 'vue'
import Vuex from 'vuex'
import i18nStore from './i18n'
import Login from './login'
import Planning from './planning'
import Plans from './plans'
import Groups from './groups'
import Users from './users'
import Bridges from './bridges'
import Dashboard from './dashboard'
import Seminars from './seminars'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    i18nStore,
    Login,
    Planning,
    Plans,
    Groups,
    Users,
    Bridges,
    Dashboard,
    Seminars
  }
})
