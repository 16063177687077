import Api from '@/api'

export default {
  state: {
    av_works_list: {vars: [], data: []},
    plan_works_list: {vars: [], data: []},
    nofing: ''
  },
  actions: {
    async PLAN_EXPORT(context, {plan_id, plan_name, type = 'all', work_ids = []}) {
      try {
        let body = {}
        if (type === 'selected') {
          body.work_ids = [...work_ids]
        } else if (type === 'approved') {
          body.approved = true
        }
        const resp = await Api.plan_export(plan_id, body)
        const {status} = resp
        if (status === 200) {
          const url = window.URL.createObjectURL(new Blob([resp.data]))
          const link = document.createElement('a')
          const today = new Date().toJSON().slice(0, 10)
          link.download = `mps_export_(${plan_name})_${today}_${type}.xlsx`
          link.href = url
          link.target = '_blank'
          link.click()
          //commit('SET_AV_WORKS_LIST', resp.data)
        } else {
          throw Error('LOAD_AV_WORKS_LIST')
        }
      } catch (err) {
        throw Error(`LOAD_AV_WORKS_LIST: ${err}`)
      }
    },
    async LOAD_AV_WORKS_LIST({commit}, id) {
      try {
        const resp = await Api.load_av_works_list(id)
        const {status, data} = resp
        if (status === 200) {
          commit('SET_AV_WORKS_LIST', data)
        } else {
          throw Error('LOAD_AV_WORKS_LIST')
        }
      } catch (err) {
        throw Error(`LOAD_AV_WORKS_LIST: ${err}`)
      }
    },
    async LOAD_AV_WORK_INFO({state}, id) {
      try {
        const resp = await Api.load_av_work_info(id)
        const {status} = resp
        if (status === 200) {
          state.nofing = ''
          return resp.data
        } else {
          throw Error('LOAD_AV_WORK_INFO')
        }
      } catch (err) {
        throw Error(`LOAD_AV_WORK_INFO: ${err}`)
      }
    },
    // async LOAD_PLANS_LIST() {
    //   try {
    //     const resp = await Api.load_plans_list()
    //     const {status} = resp
    //     if (status === 200) {

    //       return resp.data
    //     } else {
    //       throw Error('LOAD_PLANS_LIST')
    //     }
    //   } catch (err) {
    //     throw Error(`LOAD_PLANS_LIST: ${err}`)
    //   }
    // },
    async LOAD_PLAN_WORKS({commit}, id) {
      try {
        const resp = await Api.load_plan_works(id)
        const {status, data} = resp
        if (status === 200) {
          commit('SET_PLAN_WORKS_LIST', {...data})
        } else {
          throw Error('LOAD_PLAN_WORKS')
        }
      } catch (err) {
        throw Error(`LOAD_PLAN_WORK: ${err}`)
      }
    },
    async LOAD_PLAN_WORK_INFO({state}, payload) {
      const {plan_id, work_id} = payload
      try {
        const resp = await Api.load_plan_work_info(plan_id, work_id)
        const {status} = resp
        state.nofing = ''
        if (status === 200) {
          return resp.data
        } else {
          throw Error('LOAD_PLAN_WORKS')
        }
      } catch (err) {
        throw Error(`LOAD_PLAN_WORK: ${err}`)
      }
    },
    async ADD_PLAN_WORKS({state}, payload) {
      const {id, works} = payload
      try {
        const resp = await Api.add_plan_works(id, works)
        const {status} = resp
        state.nofing = ''
        if (status === 201) {
          Promise.resolve('Success')
        } else {
          throw 'ADD_PLAN_WORKS'
        }
      } catch (err) {
        throw `ADD_PLAN_WORKS: ${err}`
      }
    },
    async PLAN_WORKS_APPROVAL({commit}, payload) {
      const {id, works} = payload
      try {
        const resp = await Api.plan_works_approval(id, works)
        const {status, data} = resp
        if (status === 200) {
          data.forEach((element) => {
            commit('SET_PLAN_WORK_FIELD', element)
          })
        } else {
          throw 'PLAN_WORKS_APPROVAL'
        }
      } catch (err) {
        throw `PLAN_WORKS_APPROVE: ${err}`
      }
    }
  },
  mutations: {
    SET_AV_WORKS_LIST: (state, list) => {
      state.av_works_list = {...list}
    },
    SET_PLAN_WORKS_LIST: (state, list) => {
      state.plan_works_list = {...list}
    },
    SET_PLAN_WORK_FIELD: (state, payload) => {
      const {work_id, field, value} = payload
      const ind = state.plan_works_list.data.findIndex((elem) => elem.work_id === work_id)
      if (ind > -1) {
        state.plan_works_list.data[ind][field] = value
      }
    }
  },
  getters: {}
}
