import store from '../store/'
//import i18n from '../i18n'

const AuthLayout = () => import('@/pages/Dashboard/Pages/AuthLayout.vue')
const Login = () => import('@/pages/Dashboard/Pages/Login.vue')
const Seminars = () => import('@/pages/Dashboard/Pages/Seminars.vue')
const SeminarDetails = () => import('@/pages/Dashboard/Pages/Seminar-Details.vue')

const DashboardLayout = () =>
  import('@/pages/Dashboard/Layout/DashboardLayout.vue')
// Dashboard pages

const Home = () => import('@/pages/Dashboard/Dashboard.vue')
const aMap = () => import('@/pages/Dashboard/map.vue')

const BridgesAndTunnelsTables = () =>
  import('@/pages/Dashboard/bridgesAndTunnels/tables')
const BridgesAndTunnelsMap = () =>
  import('@/pages/Dashboard/bridgesAndTunnels/map')
const BridgeAndTunnelPassport = () =>
  import('@/pages/Dashboard/bridgesAndTunnels/passport')

const Planning = () => import('@/pages/Dashboard/planning.vue')
const Plans = () => import('@/pages/Dashboard/plans.vue')
const PlanProfileForm = () => import('@/pages/Dashboard/planProfileForm.vue')
const Groups = () => import('@/pages/Dashboard/groups.vue')
const GroupProfileForm = () => import('@/pages/Dashboard/groupProfileForm.vue')
const Users = () => import('@/pages/Dashboard/users.vue')
const UserProfileForm = () => import('@/pages/Dashboard/userProfileForm.vue')
const Translate = () => import('@/pages/Dashboard/translate.vue')
const TranslateProfileForm = () =>
  import('@/pages/Dashboard/translateProfileForm.vue')

const Summary = () => import('@/pages/Dashboard/summary.vue')

const checkAuthAndAccess = async (to, from, next) => {
  if (store.getters.hasToken) {
    store.commit('SET_HEADER_AUTH')
    try {
      if (!store.getters.profileLoaded) {
        await store.dispatch('GET_MY_PROFILE')
        //console.log('ifAuthenticated - ok')
      }

      //const locales = Object.keys(i18n.messages)
      const actLang = store.getters.locale_active
      if (!store.getters.isUiTranslateLoaded) {
        await store.dispatch('LOAD_UI_TRANSLATE', actLang)
        //console.log('ifAuthenticated - ok')
      }

      //   if (to.matched.some(record => record.meta.notForUser)) {
      //     // const {irole} = store.getters.me.profile
      //     // if (irole === 'user') {
      //     //   next('/hub/pageNotFound')
      //     // } else {
      //     //   //store.commit('ITEM_STATE', to.meta.menuItem ? to.meta.menuItem : '')
      //     next()
      //     //}
      //   } else {
      //     //store.commit('ITEM_STATE', to.meta.menuItem ? to.meta.menuItem : '')
      //     next()
      //   }
      next()
    } catch {
      //console.log('ifAuthenticated - err')
      next('/login')
    }
  } else {
    next('/login')
  }
}

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
}

const routes = [
  {
    path: '/seminars',
    name: 'Seminars',
    component: Seminars
  },
  {
    path: '/seminars/pl/:stage',
    name: 'Seminar-Details',
    component: SeminarDetails
  },  
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/',
    component: DashboardLayout,
    children: [
      {
        path: 'home',
        name: 'Home',
        components: {default: Home},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'map',
        name: 'Map',
        components: {default: aMap},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'planning',
        name: 'Planning',
        components: {default: Planning},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'plans',
        name: 'Plans',
        components: {default: Plans},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'plan_add',
        name: 'Plan_add',
        component: PlanProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'add'}
      },
      {
        path: 'plan_upd/:pid',
        name: 'Plan_upd',
        component: PlanProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'upd'}
      },
      {
        path: 'users',
        name: 'Users',
        components: {default: Users},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'user_add',
        name: 'User_add',
        component: UserProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'add'}
      },
      {
        path: 'user_upd/:uid',
        name: 'User_upd',
        component: UserProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'upd'}
      },
      {
        path: 'groups',
        name: 'Groups',
        components: {default: Groups},
        beforeEnter: checkAuthAndAccess
      },

      {
        path: 'group_add',
        name: 'Group_add',
        component: GroupProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'add'}
      },
      {
        path: 'group_upd/:gid',
        name: 'Group_upd',
        component: GroupProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'upd'}
      },
      {
        path: 'summary',
        name: 'Summary',
        components: {default: Summary},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'bridges-tunnels-tables',
        name: 'BridgesAndTunnelsTables',
        components: {default: BridgesAndTunnelsTables},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'bridges-tunnels-map',
        name: 'BridgesAndTunnelsMap',
        components: {default: BridgesAndTunnelsMap},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'bridges-passport/:uuid',
        name: 'BridgesPassport',
        component: BridgeAndTunnelPassport,
        beforeEnter: checkAuthAndAccess,
        props: (route) => ({uuid: route.params.uuid, target: 'bridge'})
      },
      {
        path: 'tunnels-passport/:uuid',
        name: 'TunnelsPassport',
        component: BridgeAndTunnelPassport,
        beforeEnter: checkAuthAndAccess,
        props: (route) => ({uuid: route.params.uuid, target: 'tunnel'})
      },
      {
        path: 'translate',
        name: 'Translate',
        components: {default: Translate},
        beforeEnter: checkAuthAndAccess
      },
      {
        path: 'translate_add',
        name: 'Translate_add',
        component: TranslateProfileForm,
        beforeEnter: checkAuthAndAccess,
        props: {oper: 'add'}
      }
    ]
  },
  authPages,
  {
    path: '*',
    redirect: '/dashboard'
  }
]

export default routes
