import Api from '@/api'
import {bridge_icon_template, icon_background, icon_bridge_color} from './helper'

const basemaps = {
    basemap_tiles: [
        {
            name: 'osm',
            max_zoom: 23,
            layers: [
                {
                    category: 'osm',
                    props: {
                        crossOrigin: 'anonymous',
                        wrapX: false
                    }
                }
            ]
        },
        {
            name: 'cartodbpositron',
            layers: [
                {
                    category: 'xyz',
                    props: {
                        url:
                            'https://cartodb-basemaps-{1-4}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
                    }
                }
            ]
        },
        {
            name: 'stamentonerlite',
            layers: [
                {
                    category: 'stamen',
                    props: {
                        layer: 'toner-lite'
                    }
                }
            ]
        },
        {
            name: 'cartodbpositronnolabels',
            layers: [
                {
                    category: 'xyz',
                    props: {
                        url:
                            'https://{1-4}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png'
                    }
                }
            ]
        },
        {
            name: 'satellite',
            layers: [
                {
                    category: 'xyz',
                    props: {
                        url:
                            'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                    }
                }
            ]
        },
        {
            name: 'stamentonerbackground',
            layers: [
                {
                    category: 'stamen',
                    props: {
                        layer: 'toner-background'
                    }
                }
            ]
        },
        {
            name: 'cartodbdark_matter',
            layers: [
                {
                    category: 'xyz',
                    props: {
                        url:
                            'https://cartodb-basemaps-{1-4}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png'
                    }
                }
            ]
        },
        {
            name: 'stamenterrain',
            layers: [
                {
                    category: 'stamen',
                    props: {
                        layer: 'terrain'
                    }
                }
            ]
        },
        {
            name: 'stamentonerlabels',
            layers: [
                {
                    category: 'stamen',
                    props: {
                        layer: 'toner-labels'
                    }
                }
            ]
        },
        {
            name: 'cartodbpositrononlylabels',
            layers: [
                {
                    category: 'xyz',
                    props: {
                        url:
                            'https://{1-4}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png'
                    }
                }
            ]
        },
        {
            name: 'stamenwatercolor',
            layers: [
                {
                    category: 'stamen',
                    props: {
                        layer: 'watercolor'
                    }
                }
            ]
        }
    ]
}

const GeoJsonFeature = () => {
    return {
        type: 'Feature',
        geometry: {
            type: 'MultiPoint',
            coordinates: []
        },
        properties: {
            style: {
                stroke: {
                    color: '#000000',
                    width: 2
                }
            },
            data: [],
            localisation_keys: []
        }
    }
}

const GeoJsonTemp = () => {
    return {
        type: 'FeatureCollection',
        features: []
    }
}

const tunnels_uuid = [
    {uuid: 'b27f2f8b-f037-45d7-a8e4-e870dae4c9b2', name: 'Kolbaev Tunnel'},
    {uuid: 'c215b056-bd7a-4408-baf0-3f51e1b10c1b', name: 'Tunnel No.1'},
    {uuid: 'f9227432-a1c6-496b-a749-63c4e4305737', name: 'Taskomur Tunnel'},
    {uuid: '1fc71537-5aae-4ccf-a4c3-7b16f236c2be', name: 'Tunnel No.2'},
    {uuid: 'ec207b64-bdc0-4d5d-bf94-25020b787993', name: 'Tunnel No.3'}
]

export default {
    state: {
        bridges_coords: [],
        vectorTypes: [
            {
                key: 'bridges',
                value: 'vector_types.bridges',
                legend: [
                    {colour: '#CC3300', name: 'bridges.soundness_imminent'},
                    {colour: '#ff9966', name: 'bridges.soundness_critical'},
                    {colour: '#ffcc00', name: 'bridges.soundness_poor'},
                    {colour: '#99cc33', name: 'bridges.soundness_fair'},
                    {colour: '#339900', name: 'bridges.soundness_good'}
                ]
            },
            {key: 'tunnels', value: 'vector_types.tunnels', legend: []}
        ],
        bridges_table: {vars: [], data: []},
        mapDefaultParams: {
            viewProjection: '',
            dataProjection: '',
            map_centre: [74.37838096363889, 41.26745102049523],
            zoom: 7,
            max_zoom: undefined
        }
    },
    actions: {
        async LOAD_VECTOR_TYPES({state}) {
            return state.vectorTypes
        },

        async LOAD_TUNNELS_LIST({state}, data) {
            state.vectorTypes[
                state.vectorTypes.findIndex((vec) => vec.key === 'tunnels')
                ].legend = []
            const passport_url = 'tunnels-passport/'
            const gjSource = GeoJsonTemp()
            data.tunnels.sort((a, b) => a.reference_number - b.reference_number)
            data.tunnels.forEach((tunnel, index) => {
                const feature = GeoJsonFeature()
                feature.geometry.type = 'MultiPoint'
                const tunnel_coord = [tunnel.longitude_e, tunnel.latitude_n]
                const tunnel_name = tunnel.tunnel_name_ru

                const tunnel_pops = [
                    tunnel_name,
                    {
                        type: 'url',
                        href: `/${
                            passport_url + tunnels_uuid[index].uuid /*tunnel.tunnel_uuid*/
                        }`,
                        link: 'tunnel.passport'
                    }
                ]

                feature.geometry.coordinates.push(tunnel_coord)
                feature.properties.data.push(tunnel_pops)
                feature.properties.localisation_keys = ['', '']
                feature.properties.style.type = 'icon'
                let bridge_icon = bridge_icon_template({
                    icon_bridge_color,
                    icon_background: icon_background[index]
                })
                feature.properties.style.image = bridge_icon.replace(/#/gi, '%23')
                feature.properties.style.scale = 0.08
                feature.properties.style.opacity = 0.6
                feature.properties.style.anchor = [0.5, 1]

                gjSource.features.push(feature)
                state.vectorTypes[
                    state.vectorTypes.findIndex((vec) => vec.key === 'tunnels')
                    ].legend.push({
                    colour: icon_background[index],
                    name: tunnel.tunnel_name_ru
                })
            })
            //})
            return gjSource
        },
        async LOAD_BRIDGES_LIST({}, data) {
            const passport_url = 'bridges-passport/'
            const gjSource = GeoJsonTemp()

            data.bridges.forEach((bridge) => {
                const feature = GeoJsonFeature()
                feature.geometry.type = 'MultiPoint'
                const bridge_soundness = bridge.structural_soundness //Math.round(Math.random() * 3)
                const bridge_coord = [bridge.longitude_e, bridge.latitude_n]
                const bridge_route = /^(.+)_.*/.exec(bridge.route_name)[1]
                const bridge_full_name = `${bridge_route.replace(' ', '')} (${
                    bridge.location
                })`

                const bridge_props = [
                    bridge_full_name,
                    //bridge.bridge_uuid.substr(0, 8),
                    {
                        type: 'url',
                        href: `/${passport_url + bridge.bridge_uuid}`,
                        link: 'bridge.passport'
                    }
                ]
                feature.geometry.coordinates.push(bridge_coord)
                feature.properties.data.push(bridge_props)
                feature.properties.localisation_keys = ['', '']
                feature.properties.style.type = 'icon'
                let bridge_icon = bridge_icon_template({
                    icon_bridge_color,
                    icon_background: icon_background[bridge_soundness]
                })
                feature.properties.style.image = bridge_icon.replace(/#/gi, '%23')
                feature.properties.style.scale = 0.04
                feature.properties.style.opacity = 0.6
                feature.properties.style.anchor = [0.5, 0.5]

                gjSource.features.push(feature)
            })
            return gjSource
        },
        async LOAD_COORDS({dispatch}, payload) {
            try {
                const {vectorData} = payload
                const resp = await Api[`load_${vectorData.type}_coords`]()
                const {status, data} = resp
                if (status === 200) {
                    const list = await dispatch(
                        `LOAD_${vectorData.type.toUpperCase()}_LIST`,
                        data
                    )
                    return list
                } else {
                    throw `LOAD_COORDS: ${{status}}`
                }
            } catch (err) {
                throw `LOAD_COORDS: ${err}`
            }
        },
        async LOAD_BRIDGES_TABLE_DATA({commit}) {
            try {
                const resp = await Api.load_bridges_table()
                const {status, data} = resp
                if (status === 200) {
                    commit('SET_BRIDGES_TABLE', data)
                } else {
                    throw `LOAD_BRIDGES_TABLE_DATA: ${{status}}`
                }
            } catch (err) {
                throw `LOAD_BRIDGES_TABLE_DATA: ${err}`
            }
        },
        async LOAD_BASEMAP_TYPES({}) {
            try {
                //const resp = await Api.load_basemaps()
                const code = 200 //resp.status
                if (code === 200) {
                    return basemaps
                } else {
                    throw `Load basemaps: ${code}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_BRIDGE_DETAILS({}, uuid) {
            try {
                const resp = await Api.load_bridge_details(uuid)
                const {status, data} = resp
                if (status === 200) {
                    return data[0]
                } else {
                    throw `Load bridge details: ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_TUNNEL_DETAILS({}, uuid) {
            try {
                const resp = await Api.load_tunnels_coords()
                const {status, data} = resp
                if (status === 200) {
                    return {
                        ...data.tunnels.find(
                            (tunnel) => tunnel.tunnel_uuid === uuid
                        )
                    }
                } else {
                    throw `Load tunnel details: ${status}`
                }
            } catch (err) {
                throw err
            }
        },
        async LOAD_TUNNELS_TABLE_LIST() {
            try {
                const resp = await Api.load_tunnels_coords()
                const {status, data} = resp
                if (status === 200) {
                    return data.tunnels
                } else {
                    throw `Load tunnel details: ${status}`
                }
            } catch (err) {
                throw err
            }
        }


    },
    mutations: {
        SET_BRIDGES_TABLE(state, payload) {
            state.bridges_table = {...payload}
        },
        SET_BRIDGES_COORDS(state, payload) {
            state.bridges_coords = [...payload]
        }
    },
    getters: {}
}
