//LOAD_DASHBOARD_CHARTS
import Api from '@/api'

export default {
  state: {
    charts: []
  },
  actions: {
    async LOAD_DASHBOARD_CHARTS({}, id) {
      try {
        const res = await Api.load_dashboard_charts_data(id)
        const {status} = res
        if (status === 200) {
          return res.data
        } else {
          throw `LOAD_CHART_DATA status: ${status}`
        }
      } catch (err) {
        console.log(err.status)
        throw `Error LOAD_CHART_DATA: ${err}`
      }
    }
  },
  mutations: {},
  getters: {}
}
