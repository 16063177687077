export default {
  state: {
    list: [
      {
        stage: '1',
        date: '25.05.2021',
        link: 'https://rams.kg/seminars/pl/1',
        meetroom: 'https://teams.live.com/meet/94447190181839',
        participants:
          'УАД Ош-Баткен-Исфана, ДЭУ-2, ДЭУ-13, ДЭУ-46, УАД-Ош-Сары-Таш-Иркэштам, ДЭУ-16, ДЭУ-21, ДЭУ-37, ДЭУ-44, ДЭУ-45, ДЭУ-956, ДЭУ-960'
      },
      {
        stage: '2',
        date: '27.05.2021',
        link: 'https://rams.kg/seminars/pl/2',
        meetroom: 'https://teams.live.com/meet/94511417043309',
        participants:
          'УАД Джалал-Абад-Балыкчы, ДЭУ-12, ДЭУ-17, ДЭУ-27, ДЭУ-31, ДЭУ-50, ДЭУ-51, ДЭУ-52'
      },
      {
        stage: '3',
        date: '01.06.2021',
        link: 'https://rams.kg/seminars/pl/3',
        meetroom: 'https://teams.live.com/meet/94398331106640',
        participants:
          'Регинальный отдел №3, ДЭУ-19, ДЭУ-36, ДЭУ-47, ДЭУ-48, УАД Бишкек-Нарын-Торугарт, ДЭУ-32, ДЭУ-34, ДЭУ-39, ДЭУ-41, ДЭУ-955, ДЭУ-957'
      },
      {
        stage: '4',
        date: '03.06.2021',
        link: 'https://rams.kg/seminars/pl/4',
        meetroom: 'https://teams.live.com/meet/9429748864464',
        participants:
          'Регинальный отдел №4, ДЭУ-3, ДЭУ-7, ДЭУ-10, ДЭУ-11, ДЭУ-33, ДЭУ-35'
      },
      {
        stage: '5',
        date: '08.06.2021',
        link: 'https://rams.kg/seminars/pl/5',
        meetroom: 'https://teams.live.com/meet/94104843298932',
        participants:
          'Региональный отдел №2, ДЭУ-8, ДЭУ-14, ДЭУ-15, ДЭУ-18, ДЭУ-20, ДЭУ-24'
      },
      {
        stage: '6',
        date: '10.06.2021',
        link: 'https://rams.kg/seminars/pl/6',
        meetroom: 'https://teams.live.com/meet/94348588022735',
        participants:
          'Региональный отдел №1, ДЭУ-1, ДЭУ-25, ДЭУ-28, ДЭУ-40, ДЭУ-42, ДЭУ-43, ДЭУ-954, ДЭУ-958 '
      },
      {
        stage: '7',
        date: '15.06.2021',
        link: 'https://rams.kg/seminars/pl/7',
        meetroom: 'https://teams.live.com/meet/94501556589426',
        participants:
          'ГДАД Бишкек-Ош, ДЭУ-5, ДЭУ-9, ДЭУ-22, ДЭУ-23, ДЭУ-26, ДЭУ-30, ДЭУ-38, ДЭУ-956 '
      }
    ]
  },
  actions: {
    async GET_SEMINARS({state}) {
      return state.list
    }
  }
}
