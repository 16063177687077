import Api from '@/api'

export default {
  state: {
    list: [],
    isPlansOper: false,
    currentPlan: undefined
  },
  actions: {
    async LOAD_PLAN_LIST({commit}) {
      try {
        commit('SET_PLAN_OPER', true)
        const res = await Api.load_plan_list()
        const {status} = res
        if (status === 200) {
          commit('SET_PLAN_LIST', res.data)
          const currentPlan = await Api.load_plan_current()
          if (currentPlan.status === 200) {
            commit('CHANGE_CURRENT_PLAN', currentPlan.data.plan_id)
          }
        } else {
          throw `Load plan list status: ${status}`
        }
      } catch (err) {
        throw `Load Plan list: ${err}`
      } finally {
        commit('SET_PLAN_OPER', false)
      }
    },
    async LOAD_PLAN_BY_ID({commit}, id) {
      try {
        commit('SET_PLAN_OPER', true)
        const res = await Api.load_plan_by_id(id)
        const {status} = res
        if (status === 200) {
          return res.data
        } else {
          throw 'Error LOAD_PLAN_BY_ID'
        }
      } catch (err) {
        throw `${err.response.data.message}`
      } finally {
        commit('SET_PLAN_OPER', false)
      }
    },
    async ADD_PLAN({commit}, plan) {
      try {
        commit('SET_PLAN_OPER', true)
        const res = await Api.add_plan(plan)
        if (res.status === 201) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'filed'
      } finally {
        commit('SET_PLAN_OPER', false)
      }
    },
    async UPD_PLAN({commit}, payload) {
      try {
        const {id, plan} = payload
        commit('SET_PLAN_OPER', true)
        const res = await Api.upd_plan(id, plan)
        if (res.status === 200) {
          return 'success'
        } else {
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'filed'
      } finally {
        commit('SET_PLAN_OPER', false)
      }
    },
    async PLAN_CHANGE_LOCK_STATUS({}, id) {
      try {
        const res = await Api.plan_change_lock_status(id)
        if (res.status === 200) {
          return Boolean(res.data.locked_at)
        }else{
          throw res.status
        }
      } catch (err) {
        throw err.response ? `${err.response.data.message}` : 'filed'
      }
    }
    // async DEL_PLAN({commit}, id) {
    //   try {
    //     commit('SET_USER_OPER', true)
    //     const res = await Api.del_user(id)
    //     if (res.status === 200) {
    //       commit('DEL_USER_FROM_LIST', id)
    //     } else {
    //       throw res.status
    //     }
    //   } catch (err) {
    //     throw `${err.response.data.message}`
    //   } finally {
    //     commit('SET_USER_OPER', false)
    //   }
    // }
  },
  mutations: {
    SET_PLAN_LIST(state, list) {
      state.list = [...list]
    },
    // DEL_PLAN_FROM_LIST(state, id) {
    //   let ind = state.list.findIndex((row) => row.id === id)
    //   if (ind >= 0) {
    //     state.list.splice(ind, 1)
    //   }
    // },
    SET_PLAN_OPER(state, val) {
      state.isPlanOper = val
    },
    CHANGE_CURRENT_PLAN(state, id) {
      state.currentPlan = id
    }
  },
  getters: {}
}
