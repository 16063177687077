import axios from 'axios'

const API_ROOT = 'https://rams.kg/api'

const Api = axios.create({
  baseURL: API_ROOT,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer`
  }
})

const type_json = {'Content-Type': 'application/json'}

export default {
  /**
   *
   * @param {number} token JWT token
   */
  setHeaderAuth(token) {
    Api.defaults.headers['Authorization'] = `Bearer ${token}`
  },

  /**
   *
   * @returns
   */
  isSetHeaderAuth() {
    const authHeader = Api.defaults.headers['Authorization']
    return /Bearer (.*)/i.test(authHeader)
  },

  delHeaderAuth() {
    delete Api.defaults.headers['Authorization']
  },
  /** Auth management */
  login(data) {
    const {login, password} = data
    return Api.post(`/auth/login`, {
      email: login,
      password
    })
  },
  logout() {
    return Api.post(`/auth/logout`)
  },
  password_recovery_request(email) {
    return Api.post(
      `/users/password-reset-request`,
      {email},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  get_profile() {
    return Api.get(`/users/me`, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  PLANS  ---------------------*/
  /** */
  /** get plan list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_plan_list() {
    return Api.get(`/planning/plans`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get current plan
   * @return {Promise<*> []} - 200  Default Response
   * {
       "plan_id": Integer
   * }
   * @throws Error
   */
  load_plan_current() {
    return Api.get(`/planning/plans/current`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load plan by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_plan_by_id(id) {
    return Api.get(`/planning/plans/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** changing plan lock status
   * @param {integer} id
   * {
      "plan_id": "integer",
    }
   * @return {Promise<*> {}} - 200  Default Response
   {
    "id": 10,
    "name": "newPlan2020-5years",
    "created_at": "2020-04-27 21:47:10",
    "updated_at": "2020-09-14 10:37:11",
    "locked_at": null
    }
   * @throws Error
   */
  plan_change_lock_status(id) {
    return Api.put(`/planning/plans/${id}/lock`, {
      headers: {
        ...type_json
      }
    })
  },

  /** add plan
   * @param {object} group
   * {
      "name": "string",
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  add_plan(plan) {
    return Api.post(`/planning/plans`, plan, {
      headers: {
        ...type_json
      }
    })
  },
  /** upd plan
   * @param {integer} id
   * @param {object} plan
   * {
      "name": "string",
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  upd_plan(id, plan) {
    return Api.put(`/planning/plans/${id}`, plan, {
      headers: {
        ...type_json
      }
    })
  },

  /*---------  GROUPS  ---------------------*/
  /** */
  /** get group list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_group_list() {
    return Api.get(`/groups`, {
      headers: {
        ...type_json
      }
    })
  },
  /** load group by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_group_by_id(id) {
    return Api.get(`/groups/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load region list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */

  load_region_list() {
    return Api.get(`/groups/regions`, {
      headers: {
        ...type_json
      }
    })
  },

  /** add group
   * @param {object} group
   * {
      "name_en": "string",
      "name_kg": "string",
      "name_ru": "string",
      "access_level": 0,
      "region_id": 0
}
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */

  add_group(group) {
    return Api.post(`/groups`, group, {
      headers: {
        ...type_json
      }
    })
  },
  /** upd group
   * @param {integer} id
   * @param {object} group
   * {
      "name_en": "string",
      "name_kg": "string",
      "name_ru": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  upd_group(id, group) {
    return Api.put(`/groups/${id}`, group, {
      headers: {
        ...type_json
      }
    })
  },
  /** del group
   * @param {integer} id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  del_group(id) {
    return Api.delete(`/groups/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /*---------  USERS  ---------------------*/
  /** */
  /** get users list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_users_list() {
    return Api.get(`/users`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get users groups
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_users_groups() {
    return Api.get(`/groups`, {
      headers: {
        ...type_json
      }
    })
  },

  /** load user by id
   * @param {integer} id // user id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_user_by_id(id) {
    return Api.get(`/users/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add user
   * @param {object} user
   * {
      "email": "string",
      "first_name": "string",
      "last_name": "string",
      "group_id": integer,
      "role": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  add_user(user) {
    return Api.post(
      `/users`,
      {...user},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** upd user
   * @param {integer} id
   * @param {object} user
   * {
      "email": "string",
      "first_name": "string",
      "last_name": "string",
      "group_id": integer,
      "role": "string"
    }
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  upd_user(id, user) {
    return Api.put(
      `/users/${id}`,
      {...user},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** del user
   * @param {integer} id
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  del_user(id) {
    return Api.delete(`/users/${id}`, {
      headers: {
        ...type_json
      }
    })
  },

  /*  ---------  PLANNING  ---------------------*/
  /** */
  /** get av works list
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_av_works_list(id = null) {
    return Api.get(
      `/planning/plans/${id}/available_works`,
      {plan_id: id},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** get av work description
   * @return {Promise<*> []} - 200  Default Response
   * @throws Error
   */
  load_av_work_info(id) {
    return Api.get(`/planning/works/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan's list
   * @return {Promise<*> {
   *  "id": integer,
   *  "name": string}}
   * - 200  Default Response
   * @throws Error
   */
  load_plans_list() {
    return Api.get(`/planning/plans`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan info
   * @return {Promise<*> {
   *  "id": integer,
   *  "name": string}
   *  "created_at": date}
   * - 200  Default Response
   * @throws Error
   */
  load_plan_info(id) {
    return Api.get(`/planning/plans/${id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** get plan works
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  load_plan_works(id) {
    return Api.get(`/planning/plans/${id}/works`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add new works to plan
   * @param {integer} id // Plan id
   * @param {Array[string]} works // ["id1", "id2", ...]
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  add_plan_works(id, works) {
    return Api.post(
      `/planning/plans/${id}/works`,
      {work_ids: works},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** plan work info
   * @param {integer} plan_id // Plan id
   * @param {integer} work_id // Plan work id
   * @return {Promise<*> {}} - 200  Default Response
   * @throws Error
   */
  load_plan_work_info(plan_id, work_id) {
    return Api.get(`/planning/plans/${plan_id}/works/${work_id}`, {
      headers: {
        ...type_json
      }
    })
  },
  /**  plan works approval
   * @param {integer} id // Plan id
   * @param {Array[string]} works // ["id1", "id2", ...]
   * @return {Promise<*>
   *  Array[{
   *     "id": 24,
   *     "field": "approved_ddh_by",
   *     "value": null
   *  }]
   * } - 200  Default Response
   * * @throws Error
   */
  plan_works_approval(id, works) {
    return Api.put(
      `/planning/plans/${id}/works/approval`,
      {work_ids: works},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** export plan
   * @param {integer} plan_id // Plan id
   * @return {Promise<*> {blob}} - 200  Default Response
   * @throws Error
   */
  plan_export(plan_id, body) {
    return Api.post(`/planning/plans/${plan_id}/export`, {...body}, {
      responseType: 'blob',
      timeout: 30000,
      headers: {
        Accept: 'application/vnd.ms-excel',
        ...type_json
      }
    })
  },

  /*  ---------  TRANSLATING  ---------------------*/
  /** list of all translating keys
   * @return {Promise<*> [item]}
   * item: {
        "id": number,
        "category": string,
        "key": string,
        "en": string,
        "ru": string,
        "kg": string
        }
   ,} - 200  Default Response
   * @throws Error
   */
  load_translate_list() {
    return Api.get(`/localisation/all`, {
      headers: {
        ...type_json
      }
    })
  },
  /** User interface's translate
   * @return {Promise<*> {item}}
   * category: {
   *  key: string
   * } - 200  Default Response
   * @throws Error
   */
  load_ui_messages(lang) {
    return Api.get(`/localisation/${lang}`, {
      headers: {
        ...type_json
      }
    })
  },
  /** add translate entry
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @return {Promise<*> {}} - 201  Default Response
   * {
      "id": integer,
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @throws Error
   */
  add_translate_entry(item) {
    return Api.post(
      `/localisation`,
      {...item},
      {
        headers: {
          ...type_json
        }
      }
    )
  },
  /** upd translate entry
   * @param {integer} id
   * @param {object} entry
   * {
      "category": "string",
      "key": "string",
      "en": "string",
      "kg": "string",
      "ru": "string"
    }
   * @return {Promise<*> []} - 201  Default Response
   * @throws Error
   */
  upd_translate_entry(id, item) {
    return Api.put(
      `/localisation/${id}`,
      {...item},
      {
        headers: {
          ...type_json
        }
      }
    )
  },

  /*  ---------  DASHBOARD  ---------------------*/
  /** Charts data
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_dashboard_charts_data(plan_id) {
    return Api.get(`/planning/plans/${plan_id}/stats`, {
      headers: {
        ...type_json
      }
    })
  },

  /*  ---------  Bridges&Tonnels  ---------------------*/
  /** Table data
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridges_table() {
    return Api.get(`/bridges/table`, {
      headers: {
        ...type_json
      }
    })
  },
  /** Bridges coords
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridges_coords() {
    return Api.get(`/bridges/coords`, {
      headers: {
        ...type_json
      }
    })
  },
  /** tunnels coords
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_tunnels_coords() {
    return Api.get(`/tunnels/coords`, {
      headers: {
        ...type_json
      }
    })
  },
  /** Bridge details
   * @param {string} uuid
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_bridge_details(uuid) {
    return Api.get(`/bridges/${uuid}`, {
      headers: {
        ...type_json
      }
    })
  }
}
